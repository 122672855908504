html,
body,
#root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.App {
  position: relative;
  width: 100%;
  height: 100%;
}

.App {
  text-align: center;
}

/* .App-logo {
  height: 40vmin;
  pointer-events: none;
} */
/* 
@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */

.App-header {
  /* background-color: #282c34; */
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.App {
  text-align: center;
  background: linear-gradient(
    to bottom,
    #333 0%,
    #000 100%
  ); /* Gradient from grey to black */
  height: 100vh; /* Full viewport height */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  color: #f0f0f0; /* Light grey text for better contrast on dark background */
}

.App-header {
  font-family: "Arial", sans-serif;
  font-size: 48px;
  color: #f0f0f0;
  text-shadow: 2px 2px 4px rgb(0, 0, 0), 5px 5px 5px rgb(37, 37, 37);
  padding-top: 0px; /* Maintain some padding at the top for aesthetic balance */
  padding-bottom: 0px; /* Remove padding below the header */
  margin-bottom: 0px; /* Ensure no bottom margin */
  line-height: 40px;
}

img {
  border-radius: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s ease-in-out, filter 0.3s ease;
  max-width: 80%;
  height: auto;
  margin-top: 0px; /* Ensure there's no top margin */
  padding-top: 0px; /* Maintain some padding at the top for aesthetic balance */
  padding-bottom: 0px; /* Remove padding below the header */
  padding: 20px;
}
